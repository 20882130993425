<template>
  <div id="usersEdit">
    <b-card no-body class="mb-0">
      <div class="m-2">
        <h5 class="mb-2">Editar Usuario</h5>
        <b-row>
          <b-col cols="12">
            <users-form-edit :user="user"></users-form-edit>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import UsersFormEdit from './components/UsersFormEdit'

  export default {
    name: 'UsersEdit',
    props: ['user'],
    components: {UsersFormEdit}
  }
</script>
