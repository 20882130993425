<template>
  <div id="users-form-edit">
    <b-form>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Nombre" label-for="name">
            <b-form-input id="name"
              v-model="editForm.name"
              name="name"
              placeholder="Escribe el nombre aqui"
              :state="nameErrors.length > 0 ? false:null"
              @input="$v.editForm.name.$touch()"/>
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Apellido" label-for="last-name">
            <b-form-input id="last-name"
              v-model="editForm.lastName"
              name="last-name"
              placeholder="Escribe el apellido aqui"
              :state="lastnameErrors.length > 0 ? false:null"
              @input="$v.editForm.lastName.$touch()"/>
            <small class="text-danger">{{ lastnameErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email"
              v-model="editForm.email"
              name="email"
              type="email"
              disabled
              placeholder="Escribe el email aqui"/>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Estado" label-for="status">
            <v-select
              v-model="editForm.state"
              label="name"
              placeholder="Selecciona un estado"
              :options="status"
              :reduce="status => status.id"
              @input="$v.editForm.state.$touch()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ stateErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Rol" label-for="role">
            <v-select
              v-model="editForm.role"
              label="name"
              placeholder="Selecciona un rol"
              :options="roles"
              :reduce="roles => roles.name"
              autocomplete="off"
              @input="$v.editForm.role.$touch()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ roleErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Permisos" label-for="permissions">
            <v-select
              autocomplete
              multiple
              label="name"
              v-model="editForm.permissions"
              placeholder="Selecciona un permiso"
              :options="permissions"
              :reduce="permissions => permissions.name">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12" v-if="checkAgenciesSelect && editForm.role === 'RealstateUser' || editForm.role === 'RealstateAdmin' || editForm.role === 'RealstateVendor'">
          <b-form-group label="Inmobiliaria" label-for="realstateagency">
            <v-select
              v-model="agencySelected"
              label="name"
              placeholder="Selecciona una inmobiliaria"
              :options="realStateAgencies"
              :reduce="realStateAgencies => realStateAgencies.id"
              >
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12" v-if="checkProjectsSelect && editForm.role === 'RealstateUser'">
          <b-form-group label="Proyectos" label-for="projects">
            <v-select
              :disabled="agencySelected === '' || agencySelected === null"
              multiple
              autocomplete
              label="name"
              v-model="userData.userProjects"
              placeholder="Selecciona un proyecto"
              :options="agencyProjects"
              :reduce="agencyProjects => agencyProjects.id"
              >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()" >
                <b-button
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_updateUser()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                    Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'UsersFormEdit',
    props: ['user'],
    data(){
      return {
        loadingData: false,
        overlayForm: false,
        agencySelected: '',
        userData: {
          userProjects: [],
          userProjectsFirstLoading: true,
        },
        editForm: {
          name: '',
          lastName: '',
          email: '',
          state: 0,
          role: '',
          permissions: [],
          userProjects: '',
        },
        status: [
          {id: 0, name: 'Inactivo'},
          {id: 1, name: 'Activo'}
        ],
      }
    },
    validations: {
      editForm: {
        name: {
          required,
        },
        lastName: {
          required
        },
        state: {
          required
        },
        role: {
          required
        },
      },
    },
    mounted(){
    },
    beforeMount() {
      this._fetchRoles()
      this._fetchPermissions()
      this._fetchRealStateAgencies()
      this._fetchUsersRealStateAgencies()
      this._setEditForm()
    },
    watch: {
      agencySelected(){
        if(this.user.projectuser.length > 0){
          if(this.agencySelected != this.user.realstateagencyuser.realstateagency.id){
            this.userData.userProjects = []
          }else{
            this.userData.userProjects = []
            this.user.projectuser.forEach(projectUser => {
              this.userData.userProjects.push(projectUser.project.id)
            })
          }
        }
      },
    },
    computed: {
      ...mapState('roles',['roles']),
      ...mapState('permissions',['permissions']),
      ...mapState('profile',['realStateAgencies','usersRealStateAgencies','profile']),
      agencyProjects(){
          let aux = this.realStateAgencies.filter(agency => {return agency.id === this.agencySelected})
          return aux.length > 0 ? aux[0].projects : []
      },
      checkAgenciesSelect(){
        if(this.roles[0]){
          return this.roles[0].name === 'Admin' || this.roles[0].name === 'SuperAdmin' ? true : false
        }
      },
      checkProjectsSelect(){
        if(this.roles[0]){
          return this.roles[0].name === 'Admin' || this.roles[0].name === 'SuperAdmin' || this.roles[0].name === 'Realstateadmin' || this.roles[0].name === 'Realstatevendor' ? true : false
        }
      },
      nameErrors () {
        const errors = []
        if (!this.$v.editForm.name.$dirty) return errors
        !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      lastnameErrors () {
        const errors = []
        if (!this.$v.editForm.lastName.$dirty) return errors
        !this.$v.editForm.lastName.required && errors.push('El Apellido es obligatorio')
        return errors
      },
      roleErrors () {
        const errors = []
        if (!this.$v.editForm.role.$dirty) return errors
        !this.$v.editForm.role.required && errors.push('El rol es obligatorio')
        return errors
      },
      stateErrors () {
        const errors = []
        if (!this.$v.editForm.state.$dirty) return errors
        !this.$v.editForm.state.required && errors.push('El estado es obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('users',['updateUsers']),
      ...mapActions('profile',['fetchUsersRealStateAgencies','fetchRealStateAgencies',"fetchRealStateAgenciesNoPagination"]),
      ...mapActions('roles',['fetchRoles']),
      ...mapActions('permissions',['fetchPermissions']),
      async _updateUser () {
        if(this.editForm.role === 'RealstateAdmin' || this.editForm.role === 'RealstateVendor'){
          this.editForm.userProjects = ''
        }else{
          this.editForm.userProjects = this.userData.userProjects.toString()
        }
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.editForm.realstateagency_id = this.agencySelected
          await this.updateUsers(this.editForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'El usuario ha sido editado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.editForm = {
              name: '',
              lastName: '',
              email: '',
              state: '',
              role: '',
              permissions: [],
              userProjects: []
            }
            this.$v.editForm.$reset()
            this.$router.push({ name: 'users'})
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'El usuario no ha podido ser editado',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {

          })
        }
      },
      async _fetchRoles () {
        await this.fetchRoles()
      },
      async _fetchPermissions () {
        await this.fetchPermissions()
      },
      async _fetchRealStateAgencies () {
        await this.fetchRealStateAgenciesNoPagination()
      },
      async _fetchUsersRealStateAgencies () {
        await this.fetchUsersRealStateAgencies(this.user.id).then(res => {
          if(this.user.realstateagencyuser != null){
            this.agencySelected = this.user.realstateagencyuser.realstateagency.id
          }
        })
      },
      _setEditForm() {
        const self = this
        this.editForm.id = this.user.id
        this.editForm.name = this.user.name
        this.editForm.lastName = this.user.last_name
        this.editForm.email = this.user.email
        this.editForm.state = this.user.status
        this.editForm.role = this.user.roles[0].name
        this.user.permissions.forEach(function (permission) {
          self.editForm.permissions.push(permission.name)
        })
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>
